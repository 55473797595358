<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <v-row justify="center" align="center">
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-avatar size="164">
                <v-img
                  :src="entity.foto_perfil"
                  alt="Foto de perfil"
                >
                </v-img>
              </v-avatar>
            </v-col>
            <v-col
              cols="12"
              md="9"
              lg="9"
              xl="9"
            >
              <v-card flat>
                <kn-form-title :title="entity.nombre" routerName="Empleados" />
                <v-card-text>
                  <p class="mb-1">{{ entity.tipo_empleado.dato }}</p>
                  <p class="mb-1">Grupo: {{ entity.grupo }}</p>
                  <p class="mb-1">No. Credencial: 4478532</p>
                  <p class="mb-1">Tel: {{ entity.datos_personales.telefono_movil }}</p>
                  <p class="mb-1">{{ fullAddress }}</p>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    tile
                    color="primary"
                    small
                    @click="goToEmployeeInfo"
                  >Editar información</v-btn>
                </v-card-actions>
              </v-card>
              <v-card flat>
                <v-card-text>
                  <v-row class="my-3">
                    <v-btn
                      color="info"
                      text
                      tile
                      disabled
                    >
                      <span class="text-none">Incapacidades</span>
                    </v-btn>
                    <v-divider class="info" vertical />
                    <v-btn
                      color="info"
                      text
                      tile
                      disabled
                    >
                      <span class="text-none">Vacaciones</span>
                    </v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            align="center"
            no-gutters
          >
            <v-col
              cols="4"
              md="1"
              lg="1"
              xl="1"
              class="text-center graydefault--text"
            >Del</v-col>
            <v-col
              cols="8"
              md="3"
              lg="3"
              xl="3"
            >
              <v-text-field
                v-model="dateRange.from"
                dense
                outlined
                type="date"
                hide-details
              />
            </v-col>
            <v-col
              cols="4"
              md="1"
              lg="1"
              xl="1"
              class="text-center graydefault--text"
            >Al</v-col>
            <v-col
              cols="8"
              md="3"
              lg="3"
              xl="3"
            >
              <v-text-field
                v-model="dateRange.to"
                dense
                outlined
                type="date"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
            >
              <v-btn
                class="ml-2"
                tile
                color="primary"
                @click="filterByDateRange"
              >
                Filtrar
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card flat tile>
                <v-card-title class="py-2 gray1 purple--text">
                  Estado de cuenta
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  loading-text="Cargando... Espera por favor"
                  no-data-text="Aún no hay registros"
                  no-results-text="No se encontraron coincidencias"
                  :footer-props="{
                    itemsPerPageText: 'Elementos por página'
                  }"
                >
                  <template v-slot:item.fecha_elaboracion="{ item }">
                      {{ item.fecha_elaboracion.substring(0, 10) }}
                  </template>
                  <template v-slot:item.sub_total="{ item }">
                    <span class="green--text">
                      {{ item.sub_total }}
                    </span>
                  </template>
                  <template v-slot:item.total_impuestos="{ item }">
                    <span class="green--text">
                      {{ item.total_impuestos }}
                    </span>
                  </template>
                  <template v-slot:item.total_descuento="{ item }">
                    <span class="green--text">
                      {{ item.total_descuento }}
                    </span>
                  </template>
                  <template v-slot:item.total_egreso="{ item }">
                    <span class="green--text">
                      {{ item.total_egreso }}
                    </span>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { api } from '@/api/axios-base';
import { dateUtilsMixin } from '@/mixins/dateUtilsMixin'
import KnFormTitle from '../../components/KnFormTitle.vue';
export default {
  components: { KnFormTitle },
  mixins: [dateUtilsMixin],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      headers: [
        { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
        { text: 'Concepto', value: 'categoria.dato', class: 'purple--text'},
        { text: 'Sub total', value: 'sub_total', class: 'purple--text'},
        { text: 'Impuestos', value: 'total_impuestos', class: 'purple--text'},
        { text: 'Descuentos', value: 'total_descuento', class: 'purple--text'},
        { text: 'Total', value: 'total_egreso', class: 'purple--text' },
        { text: '', value: 'acciones'}
      ],
      loading: false,
      fullAddress: null,
      items: [],
      expenses: [],
      employeeId: null,
      dateRange: {
        from: '',
        to: ''
      },
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData'])
  },
  async created() {
    this.employeeId = this.entity.id
    await this.fetchAddresById(this.entity.datos_personales.direccion, this.institutionId)
    const today = new Date()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth()
    this.dateRange.from = this.getFirstDayOfMonth(currentYear, currentMonth)
    this.dateRange.to = this.getLastDayOfMonth(currentYear, currentMonth)
    const filterObj = {
      id_empleado: this.employeeId,
      fecha_inicio: this.dateRange.from,
      fecha_fin: this.dateRange.to,
      id_institucion_educativa: this.institutionId
    }
    const expenseItems = await this.fetchExpensesByEmployee(filterObj)
    this.items = expenseItems
    // console.log('expense items: ', expenseItems);
  },
  methods: {
    async fetchAddresById(addressId, institutionId) {
      try {
        const response = await api.get(`/personas/api/direccion-by-ie/${addressId}/?id_institucion=${institutionId}`)
        const responseData = await response.data
        // console.log('Respuesta direccion por id:', responseData);
        const {
          calle: ca,
          numero_exterior: ne,
          numero_interior: ni,
          colonia: col,
          ciudad: ci,
          estado: es,
          codigo_postal: cp
        } = responseData
        this.fullAddress = `${ca} ${ne}${ni ? ' '+ni : ''}, Col. ${col}, ${ci}, ${es}. CP. ${cp.codigo_postal}`
      } catch (error) {
        console.error('Error al obtener direccion por id', error);
      }
    },
    async fetchExpensesByEmployee(
      {
        id_empleado,
        fecha_inicio,
        fecha_fin,
        id_institucion_educativa
      }
    ) {
      // console.log('id_empleado:', id_empleado);
      // console.log('fecha inicio: ', fecha_inicio);
      try {
        const response = await api.post('/administracion/detalle-egreso-por-empleado', {
            id_empleado: id_empleado,
            fecha_inicio: fecha_inicio,
            fecha_fin: fecha_fin,
            id_institucion_educativa: id_institucion_educativa
          })
        const responseData = await response.data
        // console.log('Respuesta egresos por empleado', responseData);
        return responseData.resultado
      } catch (error) {
        console.error('Error al obtener egresos por empleado.', error);
      }
    },
    async filterByDateRange() {
      this.loading = true
      const filterObj = {
        id_empleado: this.employeeId,
        fecha_inicio: this.dateRange.from,
        fecha_fin: this.dateRange.to,
        id_institucion_educativa: this.institutionId
      }
      this.items = await this.fetchExpensesByEmployee(filterObj)
      this.loading = false
    },
    goToEmployeeInfo() {
      this.$router.push({
        name: 'Editar Empleado',
        params: {
          entity: this.entity
        }
      })
    }
  }
}
</script>

<style>
</style>